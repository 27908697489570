import { styled, View } from '@talkspace/react-toolkit';

const NotReadyView = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    minWidth: 343,
    width: '100%',
    height: 216,
    gap: spacing('space200'),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colorRoles.surfaces.neutralSubtleDefault,
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    borderRadius: spacing('space100'),
  };
});

export default NotReadyView;
