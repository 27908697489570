import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const ListIcon = ({ width = 21, height = 20, color, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'List Icon';
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={titleText}
      {...props}
    >
      <Path
        d="M4.5 6.5C4.89782 6.5 5.27936 6.34196 5.56066 6.06066C5.84196 5.77936 6 5.39782 6 5C6 4.60218 5.84196 4.22064 5.56066 3.93934C5.27936 3.65804 4.89782 3.5 4.5 3.5C4.10218 3.5 3.72064 3.65804 3.43934 3.93934C3.15804 4.22064 3 4.60218 3 5C3 5.39782 3.15804 5.77936 3.43934 6.06066C3.72064 6.34196 4.10218 6.5 4.5 6.5ZM8.5 4C7.94688 4 7.5 4.44687 7.5 5C7.5 5.55312 7.94688 6 8.5 6H17.5C18.0531 6 18.5 5.55312 18.5 5C18.5 4.44687 18.0531 4 17.5 4H8.5ZM8.5 9C7.94688 9 7.5 9.44688 7.5 10C7.5 10.5531 7.94688 11 8.5 11H17.5C18.0531 11 18.5 10.5531 18.5 10C18.5 9.44688 18.0531 9 17.5 9H8.5ZM8.5 14C7.94688 14 7.5 14.4469 7.5 15C7.5 15.5531 7.94688 16 8.5 16H17.5C18.0531 16 18.5 15.5531 18.5 15C18.5 14.4469 18.0531 14 17.5 14H8.5ZM4.5 16.5C4.89782 16.5 5.27936 16.342 5.56066 16.0607C5.84196 15.7794 6 15.3978 6 15C6 14.6022 5.84196 14.2206 5.56066 13.9393C5.27936 13.658 4.89782 13.5 4.5 13.5C4.10218 13.5 3.72064 13.658 3.43934 13.9393C3.15804 14.2206 3 14.6022 3 15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5ZM6 10C6 9.80302 5.9612 9.60796 5.88582 9.42597C5.81044 9.24399 5.69995 9.07863 5.56066 8.93934C5.42137 8.80005 5.25601 8.68956 5.07403 8.61418C4.89204 8.5388 4.69698 8.5 4.5 8.5C4.30302 8.5 4.10796 8.5388 3.92597 8.61418C3.74399 8.68956 3.57863 8.80005 3.43934 8.93934C3.30005 9.07863 3.18956 9.24399 3.11418 9.42597C3.0388 9.60796 3 9.80302 3 10C3 10.197 3.0388 10.392 3.11418 10.574C3.18956 10.756 3.30005 10.9214 3.43934 11.0607C3.57863 11.1999 3.74399 11.3104 3.92597 11.3858C4.10796 11.4612 4.30302 11.5 4.5 11.5C4.69698 11.5 4.89204 11.4612 5.07403 11.3858C5.25601 11.3104 5.42137 11.1999 5.56066 11.0607C5.69995 10.9214 5.81044 10.756 5.88582 10.574C5.9612 10.392 6 10.197 6 10Z"
        fill={color || colors.accessibilityGreenDark}
      />
    </Svg>
  );
};

export default ListIcon;
