import SchedulingCancellationIcon from './SchedulingCancellationIcon';
import CouplesDefaultAvatar from './CouplesDefaultAvatar';
import ProfilePlaceholder from './ProfilePlaceholder';
import TSAvatar from './TSAvatar';
import BookingIconWithX from './BookingIconWithX';
import AssessmentHead from './AssessmentHead';
import BookingIcon from './BookingIcon';
import ChatIcon from './ChatIcon';
import ChatIconOutline from './ChatIconOutline';
import CreditCard from './CreditCard';
import CaretRight from './CaretRight';
import CaretDown from './CaretDown';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import CaretLeft from '../../designSystems/components/CaretLeft';
import CheckMark from './CheckMark';
import CircleClockPlaceholderIcon from './CircleClockPlaceholderIcon';
import ThreeDots from './ThreeDots';
import TimeZone from './TimeZone';
import TimeOff from './TimeOff';
import TherapistAvatarOverlay from './TherapistAvatarOverlay';
import TherapistAvatarOverlayVideo from './TherapistAvatarOverlayVideo';
import CalendarIcon from './CalendarIcon';
import RedX from './RedX';
import X from './X';
import XCheckbox from './XCheckbox';
import AddUser from './AddUser';
import Envelope from './Envelope';
import EnvelopeX from './EnvelopeX';
import ExclamationCircle from './ExclamationCircle';
import ExclamationSquare from './ExclamationSquare';
import SquareCheckMark from './SquareCheckMark';
import RxCircle from './RxCircle';
import ChatCircle from './ChatCircle';
import CouplesCircle from './CouplesCircle';
import ChatBubbleWithBadge from './ChatBubbleWithBadge';
import GreenCircleCheckMark from './GreenCircleCheckMark';
import GreenCircleCheckMarkV2 from './GreenCircleCheckMarkV2';
import MagnifyingGlassTherapists from './MagnifyingGlassTherapists';
import GeolocationFailure from './GeolocationFailure';
import UpwardTrend from './UpwardTrend';
import ChatBubble3Dots from './ChatBubble3Dots';
import CalendarCheckMark from './CalendarCheckMark';
import ArrowHeadRight from './ArrowHeadRight';
import InsuranceIcon from './InsuranceIcon';
import StarCircle from './StarCircle';
import HourGlass from './HourGlass';
import RxCircleTwo from './RxCircleTwo';
import Briefcase from './Briefcase';
import BrainShield from './BrainShield';
import CalendarCheck from './CalendarCheck';
import GlobeWithClock from './GlobeWithClock';
import Heart from './Heart';
import RoundBorderedBookingIcon from './RoundBorderedBookingIcon';
import Filter from './Filter';
import OpenBook from './OpenBook';
import VideoModality from './VideoModality';
import AudioModality from './AudioModality';
import ChatModality from './ChatModality';
import MessagingModality from './MessagingModality';
import TakeTheLeap from './TakeTheLeap';
import Check from './Check';
import LiveSessionIcon from './LiveSessionIcon';
import LiveSessionIconSmall from './LiveSessionIconSmall';
import LiveSessionIconSmallV2 from './LiveSessionIconSmallV2';
import LastingFromTSLogoBlue from './LastingFromTSLogoBlue';
import TherapistSearch from './TherapistSearch';
import GlobeWithLocationPointer from './GlobeWithLocationPointer';
import SparklingModalities from './SparklingModalities';
import HandshakeCircle from './HandshakeCircle';
import AvatarCircle from './AvatarCircle';
import LiveSessionCalendarIcon from './LiveSessionCalendarIcon';
import TwoFactorAuthenticationIcon from './TwoFactorAuthenticationIcon';
import ComplianceBadges from './ComplianceBadges';
import DownloadIcon from './DownloadIcon';
import PictureInPicture from './PictureInPicture';
import SorterIcon from './Sorter';
import Tiles from './Tiles';
import TaskList from './TaskList';
import Hamburger from './Hamburger';
import VideoCalendar from './VideoCalendar';
import VideoCalendarOutline from './VideoCalendarOutline';
import AudioCalendar from './AudioCalendar';
import ChatCalendar from './ChatCalendar';
import FilledEnvelope from './FilledEnvelope';
import TaskIconSmall from './TaskIconSmall';
import DoubleChevrons from './DoubleChevrons';
import Reload from './Reload';
import ArrowWithCircleLeft from './ArrowWithCircleLeft';
import ArrowWithCircleRight from './ArrowWithCircleRight';
import CloseIcon from './CloseIcon';
import RightArrowIcon from './RightArrowIcon';
import ClipBoardIcon from './ClipBoardIcon';
import ChatNow from './ChatNow';
import SparklingEnvelope from './SparklingEnvelope';
import SparklingVideo from './SparklingVideo';
import SparklingVideoPlus from './SparklingVideoPlus';
import SparklingAudioPlus from './SparklingAudioPlus';
import SparklingChatPlus from './SparklingChatPlus';
import StuckAtHomeImagination from './StuckAtHomeImagination';
import MessagingSession from './MessagingSession';
import MessagingConfirmSession from './MessagingConfimSession';
import PlanDetails from './PlanDetails';
import IDBox from './IDBox';
import SmallCalendar from './SmallCalendar';
import RoundFilledCheckmark from './RoundFilledCheckmark';
import RoundFilledExclamation from './RoundFilledExclamation';
import RoundClock from './RoundClock';
import Notepad from './Notepad';
import SparklingCalendar from './SparklingCalendar';
import SparklingOkayHand from './SparklingOkayHand';
import SuccessCheckmark from './SuccessCheckmark';
import SuccessCheckmarkV2 from './SuccessCheckmarkV2';
import IDMenuBox from './IDMenuBox';
import QuickCaseMenuBox from './QuickCaseMenuBox';
import ServicesIcon from './ServicesIcon';
import PinIcon from './PinIcon';
import InsuranceCardIcon from './InsuranceCardIcon';
import HandEnvelope from './HandEnvelope';
import Group from './Group';
import RoundContainer from './RoundContainer';
import Calendar from './Calendar';
import CalendarThreeDots from './CalendarThreeDots';
import CheckmarkTextBubble from './CheckmarkTextBubble';
import HeartHollow from './HeartHollow';
import DollarSign from './DollarSign';
import ProfileCircle from './ProfileCircle';
import NotepadCircle from './NotepadCircle';
import HeadCircle from './HeadCircle';
import SparklingInsuranceCard from './SparklingInsuranceCard';
import SparklingCheckMark from './SparklingCheckMark';
import LightningIcon from './LightningIcon';
import HeartShield from './HeartShield';
import GivingHeart from './GivingHeart';
import ContinueServiceIcon from './ContinueServiceIcon';
import ShieldIcon from './ShieldIcon';
import ShieldOutlineIcon from './ShieldOutlineIcon';
import CalendarOutlineIcon from './CalendarOutlineIcon';
import CalendarXOutlineIcon from './CalendarXOutlineIcon';
import CalendarVOutlineIcon from './CalendarVOutlineIcon';
import ChatSquareOutlineIcon from './ChatSquareOutlineIcon';
import HumanCheckmarkOutlineIcon from './HumanCheckmarkOutlineIcon';
import QuestionnaireIcon from './QuestionnaireIcon';
import InGoodHands from './InGoodHands';
import CoverageCard from './CoverageCard';
import CaringProvider from './CaringProvider';
import Swap from './Swap';
import PauseSign from './PauseSign';
import StopSign from './StopSign';
import SmallCalendarV2 from './SmallCalendarV2';
import RecurrenceIcon from './RecurrenceIcon';
import CalendarPlusIcon from './CalendarPlusIcon';
import PlayVideoIcon from './PlayVideoIcon';
import TicketIcon from './TicketIcon';
import TimeOffIcon from './TimeOffIcon';
import DidYouKnowShield from './DidYouKnowShield';
import FindingTheRightMatchShield from './FindingTheRightMatchShield';
import GrowCaseloadIcon from './GrowCaseload';
import RoundRectExclaimationMark from './RoundRectExclaimationMark';
import MbaMagnifyingGlass from './MbaMagnifyingGlass';
import MbaSparkles from './MbaSparkles';
import MbaPerson from './MbaPerson';
import MbaCheckMark from './MbaCheckMark';
import RoundEmptyContainer from './RoundEmptyContainer';
import Preferences from './Preferences';
import RepeatingArrowsCircle from './RepeatingArrowsCircle';
import RepeatingArrowsDarkIcon from './RepeatingArrowsDarkIcon';
import RepeatingArrowsLightIcon from './RepeatingArrowsLightIcon';
import ReminderIcon from './ReminderIcon';
import RotateRightIcon from './RotateRightIcon';
import PeopleIcon from './PeopleIcon';
import CountdownSpinner from './CountdownSpinner';
import EnvelopeIcon from './EnvelopeIcon';
import CirclePencilIcon from './CirclePencilIcon';
import ChatModalityV2 from './ChatModalityV2';
import VideoModalityV2 from './VideoModalityV2';
import AudioModalityV2 from './AudioModalityV2';
import BoxArrowIcon from './BoxArrowIcon';
import CloseRoundIcon from './CloseRoundIcon';
import ReopenIcon from './ReopenIcon';
import SortIcon from './SortIcon';
import CircleError from './CircleError';
import ParentConsentAvatar from './ParentConsentAvatar';
import BackArrow from './BackArrow';
import StarsIcon from './StarsIcon';
import ErrorTooltipIcon from './ErrorTooltipIcon';
import WarningTooltipIcon from './WarningTooltipIcon';
import ThumbsDown from './ThumbsDown';
import ThumbsUp from './ThumbsUp';
import PencilIconV2 from './PencilIconV2';
import SmallCircle from './SmallCircle';
import UserIcon from './UserIcon';
import DischargeSuccessIcon from './DischargeSuccessIcon';
import Clock from './Clock';
import VerticalLine from './VerticalLine';
import CircleQuestionMark from './CircleQuestionMark';
import ListIcon from './ListIcon';
import RecommendedBadge from './RecommendedBadge';

const icons = {
  Group,
  RoundContainer,
  SchedulingCancellationIcon,
  CouplesDefaultAvatar,
  ProfilePlaceholder,
  TSAvatar,
  BookingIconWithX,
  AssessmentHead,
  BookingIcon,
  ChatIcon,
  ChatIconOutline,
  CreditCard,
  CaretRight,
  CaretLeft,
  CaretDown,
  ArrowRight,
  ArrowLeft,
  CheckMark,
  CircleClockPlaceholderIcon,
  ThreeDots,
  TimeZone,
  TimeOff,
  CalendarIcon,
  RedX,
  X,
  XCheckbox,
  AddUser,
  Envelope,
  EnvelopeX,
  ExclamationCircle,
  ExclamationSquare,
  SquareCheckMark,
  RxCircle,
  ChatCircle,
  CouplesCircle,
  ChatBubbleWithBadge,
  GreenCircleCheckMark,
  GreenCircleCheckMarkV2,
  MagnifyingGlassTherapists,
  GeolocationFailure,
  UpwardTrend,
  ChatBubble3Dots,
  CalendarCheckMark,
  ArrowHeadRight,
  LiveSessionCalendarIcon,
  InsuranceIcon,
  StarCircle,
  HourGlass,
  RxCircleTwo,
  Briefcase,
  BrainShield,
  CalendarCheck,
  GlobeWithClock,
  Heart,
  RoundBorderedBookingIcon,
  Filter,
  OpenBook,
  VideoModality,
  ChatModality,
  AudioModality,
  MessagingModality,
  TakeTheLeap,
  Check,
  LiveSessionIcon,
  LiveSessionIconSmall,
  LiveSessionIconSmallV2,
  TaskIconSmall,
  LastingFromTSLogoBlue,
  TherapistSearch,
  GlobeWithLocationPointer,
  SparklingModalities,
  HandshakeCircle,
  AvatarCircle,
  TwoFactorAuthenticationIcon,
  ComplianceBadges,
  DownloadIcon,
  PictureInPicture,
  SorterIcon,
  Hamburger,
  Tiles,
  TaskList,
  VideoCalendar,
  VideoCalendarOutline,
  AudioCalendar,
  ChatCalendar,
  FilledEnvelope,
  DoubleChevrons,
  Reload,
  ArrowWithCircleLeft,
  ArrowWithCircleRight,
  CloseIcon,
  RightArrowIcon,
  ClipBoardIcon,
  ChatNow,
  SparklingEnvelope,
  SparklingVideo,
  SparklingVideoPlus,
  SparklingAudioPlus,
  SparklingChatPlus,
  StuckAtHomeImagination,
  MessagingSession,
  MessagingConfirmSession,
  PlanDetails,
  IDBox,
  SmallCalendar,
  RoundFilledCheckmark,
  RoundFilledExclamation,
  RoundClock,
  Notepad,
  SparklingCalendar,
  SparklingOkayHand,
  SuccessCheckmark,
  SuccessCheckmarkV2,
  IDMenuBox,
  QuickCaseMenuBox,
  ServicesIcon,
  PinIcon,
  InsuranceCardIcon,
  HandEnvelope,
  Calendar,
  CalendarThreeDots,
  CheckmarkTextBubble,
  HeartHollow,
  DollarSign,
  ProfileCircle,
  NotepadCircle,
  HeadCircle,
  SparklingInsuranceCard,
  SparklingCheckMark,
  LightningIcon,
  HeartShield,
  GivingHeart,
  ContinueServiceIcon,
  ShieldIcon,
  ShieldOutlineIcon,
  CalendarOutlineIcon,
  CalendarXOutlineIcon,
  CalendarVOutlineIcon,
  ChatSquareOutlineIcon,
  HumanCheckmarkOutlineIcon,
  QuestionnaireIcon,
  InGoodHands,
  CoverageCard,
  CaringProvider,
  Swap,
  PauseSign,
  StopSign,
  SmallCalendarV2,
  RecurrenceIcon,
  CalendarPlusIcon,
  PlayVideoIcon,
  TicketIcon,
  TimeOffIcon,
  DidYouKnowShield,
  RoundRectExclaimationMark,
  MbaMagnifyingGlass,
  MbaSparkles,
  MbaPerson,
  MbaCheckMark,
  RoundEmptyContainer,
  RepeatingArrowsCircle,
  RepeatingArrowsDarkIcon,
  RepeatingArrowsLightIcon,
  ReminderIcon,
  RotateRightIcon,
  PeopleIcon,
  CountdownSpinner,
  EnvelopeIcon,
  CirclePencilIcon,
  ChatModalityV2,
  VideoModalityV2,
  AudioModalityV2,
  BoxArrowIcon,
  CloseRoundIcon,
  ReopenIcon,
  SortIcon,
  CircleError,
  ParentConsentAvatar,
  BackArrow,
  StarsIcon,
  ErrorTooltipIcon,
  WarningTooltipIcon,
  ThumbsDown,
  ThumbsUp,
  PencilIconV2,
  SmallCircle,
  UserIcon,
  DischargeSuccessIcon,
  Clock,
  VerticalLine,
  RecommendedBadge,
  CircleQuestionMark,
  ListIcon,
};

export default icons;

export {
  Group,
  RoundContainer,
  SchedulingCancellationIcon,
  CouplesDefaultAvatar,
  ProfilePlaceholder,
  TSAvatar,
  LiveSessionCalendarIcon,
  BookingIconWithX,
  AssessmentHead,
  BookingIcon,
  ChatIcon,
  ChatIconOutline,
  CreditCard,
  CaretRight,
  CaretDown,
  ArrowRight,
  ArrowLeft,
  CheckMark,
  CircleClockPlaceholderIcon,
  CaretLeft,
  ThreeDots,
  TimeZone,
  TimeOff,
  TherapistAvatarOverlay,
  TherapistAvatarOverlayVideo,
  CalendarIcon,
  RedX,
  X,
  XCheckbox,
  AddUser,
  Envelope,
  EnvelopeX,
  ExclamationCircle,
  ExclamationSquare,
  SquareCheckMark,
  RxCircle,
  ChatCircle,
  CouplesCircle,
  ChatBubbleWithBadge,
  GreenCircleCheckMark,
  GreenCircleCheckMarkV2,
  MagnifyingGlassTherapists,
  GeolocationFailure,
  UpwardTrend,
  ChatBubble3Dots,
  CalendarCheckMark,
  ArrowHeadRight,
  InsuranceIcon,
  StarCircle,
  HourGlass,
  RxCircleTwo,
  Briefcase,
  BrainShield,
  CalendarCheck,
  GlobeWithClock,
  Heart,
  RoundBorderedBookingIcon,
  Filter,
  OpenBook,
  ChatModality,
  AudioModality,
  VideoModality,
  MessagingModality,
  TakeTheLeap,
  Check,
  LiveSessionIcon,
  LiveSessionIconSmall,
  LiveSessionIconSmallV2,
  TaskIconSmall,
  LastingFromTSLogoBlue,
  TherapistSearch,
  GlobeWithLocationPointer,
  SparklingModalities,
  HandshakeCircle,
  AvatarCircle,
  TwoFactorAuthenticationIcon,
  ComplianceBadges,
  DownloadIcon,
  PictureInPicture,
  SorterIcon,
  Hamburger,
  Tiles,
  TaskList,
  VideoCalendar,
  VideoCalendarOutline,
  AudioCalendar,
  ChatCalendar,
  FilledEnvelope,
  DoubleChevrons,
  Reload,
  ArrowWithCircleLeft,
  ArrowWithCircleRight,
  CloseIcon,
  RightArrowIcon,
  ClipBoardIcon,
  ChatNow,
  SparklingEnvelope,
  SparklingVideo,
  SparklingVideoPlus,
  SparklingAudioPlus,
  SparklingChatPlus,
  StuckAtHomeImagination,
  MessagingSession,
  MessagingConfirmSession,
  PlanDetails,
  IDBox,
  SmallCalendar,
  RoundFilledCheckmark,
  RoundFilledExclamation,
  RoundClock,
  Notepad,
  SparklingCalendar,
  SparklingOkayHand,
  SuccessCheckmark,
  SuccessCheckmarkV2,
  IDMenuBox,
  QuickCaseMenuBox,
  ServicesIcon,
  PinIcon,
  InsuranceCardIcon,
  HandEnvelope,
  Calendar,
  CalendarThreeDots,
  CheckmarkTextBubble,
  HeartHollow,
  DollarSign,
  ProfileCircle,
  NotepadCircle,
  HeadCircle,
  SparklingInsuranceCard,
  SparklingCheckMark,
  LightningIcon,
  HeartShield,
  GivingHeart,
  ContinueServiceIcon,
  ShieldIcon,
  ShieldOutlineIcon,
  CalendarOutlineIcon,
  CalendarXOutlineIcon,
  CalendarVOutlineIcon,
  ChatSquareOutlineIcon,
  HumanCheckmarkOutlineIcon,
  QuestionnaireIcon,
  InGoodHands,
  CoverageCard,
  CaringProvider,
  Swap,
  PauseSign,
  StopSign,
  SmallCalendarV2,
  RecurrenceIcon,
  CalendarPlusIcon,
  PlayVideoIcon,
  TicketIcon,
  TimeOffIcon,
  DidYouKnowShield,
  FindingTheRightMatchShield,
  GrowCaseloadIcon,
  RoundRectExclaimationMark,
  MbaMagnifyingGlass,
  MbaSparkles,
  MbaPerson,
  MbaCheckMark,
  RoundEmptyContainer,
  Preferences,
  RepeatingArrowsCircle,
  RepeatingArrowsDarkIcon,
  RepeatingArrowsLightIcon,
  ReminderIcon,
  RotateRightIcon,
  PeopleIcon,
  CountdownSpinner,
  EnvelopeIcon,
  CirclePencilIcon,
  ChatModalityV2,
  VideoModalityV2,
  AudioModalityV2,
  BoxArrowIcon,
  CloseRoundIcon,
  ReopenIcon,
  SortIcon,
  CircleError,
  ParentConsentAvatar,
  BackArrow,
  StarsIcon,
  ErrorTooltipIcon,
  WarningTooltipIcon,
  ThumbsDown,
  ThumbsUp,
  PencilIconV2,
  SmallCircle,
  UserIcon,
  DischargeSuccessIcon,
  Clock,
  VerticalLine,
  RecommendedBadge,
  CircleQuestionMark,
  ListIcon,
};
