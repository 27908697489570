import { Button, TextDS, View } from '@talkspace/react-toolkit';
import StarsIcon from '@talkspace/react-toolkit/src/components/icons/StarsIcon';

export default function SmartInsightsLoading() {
  return (
    <>
      <TextDS variant="bodySm" colorRole="textSubtle" style={{ width: 247, textAlign: 'center' }}>
        Sit back and relax. This can take up to 10 seconds
      </TextDS>

      <View>
        <Button text="" Icon={StarsIcon} sizeDS="slim" style={{ width: 167 }} isLoading disabled />
      </View>
    </>
  );
}
