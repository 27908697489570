import { useQuery } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

import { riskIndicationsQueryKey } from './queryKeys';
import { RiskIndication } from '../types/clinical';

const fetchRiskIndications = (roomID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/risk-indications`
  );

  return data.data;
};

export default function useQueryRiskIndications(roomID: number) {
  return useQuery<RiskIndication[], Error>({
    queryKey: riskIndicationsQueryKey(roomID),
    queryFn: fetchRiskIndications(roomID),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
