import { useMutation } from 'react-query';

import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { OAuthProviderName } from './useQueryCalendarSyncStatus';

interface PostCalendarDisconnectParams {
  therapistID: number;
  provider: OAuthProviderName;
}

const postCalendarDisconnect = async (params: PostCalendarDisconnectParams): Promise<void> => {
  const { therapistID, provider } = params;

  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/therapists/${therapistID}/calendar-sync/disconnect`,
    {
      provider,
    }
  );
};

const useMutationCalendarDisconnect = () =>
  useMutation<void, Error, PostCalendarDisconnectParams>(postCalendarDisconnect);

export default useMutationCalendarDisconnect;
