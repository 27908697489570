import moment from 'moment';
import { Tag, TextDS, Tooltip, View } from '@talkspace/react-toolkit';
import CircleInfo from '@talkspace/react-toolkit/src/designSystems/icons/CircleInfo';
import { useParams } from '@/core/routerLib';
import useQueryRiskIndications from '../../../../hooks/useQueryRiskIndications';
import { RiskIndicationSource, RiskIndicationType } from '../../../../types/clinical';

const riskLabels: Record<RiskIndicationType, string> = {
  'suicidal-ideation': 'Suicidal ideation',
  'suicidal-planning': 'Suicidal planning',
  'homicidal-ideation': 'Homicidal ideation',
  'homicidal-planning': 'Homicidal planning',
  'risk-mentioned': 'Risk mentioned',
};

const formatRiskSourceAndDate = (riskDate: string, source: RiskIndicationSource) => {
  const riskMoment = moment(riskDate);
  const currentYear = moment().year();

  const formattedDate =
    riskMoment.year() === currentYear
      ? riskMoment.format('MMM D')
      : riskMoment.format('MMM D, YYYY');

  if (source === 'intake') {
    return `${formattedDate} on intake`;
  }

  if (source === 'session') {
    return `${formattedDate} in the chat`;
  }

  return `${formattedDate} in a progress note`;
};

export default function RiskInfo() {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: riskIndications = [] } = useQueryRiskIndications(Number(roomID));

  return (
    <View style={{ gap: 4 }}>
      <View row align="center" style={{ gap: 2 }}>
        <TextDS variant="headingMd">Risk</TextDS>

        <Tooltip
          place="top"
          buttonStyle={{ display: 'flex', alignItems: 'center' }}
          tip="Risk information is based on the client’s intake, messages sent in the chat, and information in progress notes."
        >
          <CircleInfo colorType="subtlest" />
        </Tooltip>
      </View>

      <View style={{ gap: 4 }}>
        {riskIndications.map((riskIndication) => (
          <View key={riskIndication.type} style={{ flexDirection: 'row', gap: 4 }}>
            <Tag text={riskLabels[riskIndication.type]} color="criticalDefault" />

            <TextDS variant="bodySm" colorRole="textSubtle">
              {formatRiskSourceAndDate(riskIndication.date, riskIndication.source)}
            </TextDS>
          </View>
        ))}
      </View>
    </View>
  );
}
