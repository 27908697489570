import { View } from '@talkspace/react-toolkit';
import ButtonV2 from '@talkspace/react-toolkit/src/designSystems/components/Button/ButtonV2';
import { CalendarSyncError } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import styled from '../../../../core/styled';
import ModalContainer from './ModalContainer';

const Title = styled(View)(({ theme: { colors } }) => {
  return {
    fontSize: 21,
    lineHeight: '28px',
    fontWeight: 500,
    color: colors.permaOuterSpaceNew,
    textAlign: 'center',
  };
});

const Subtitle = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 15,
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    color: colors.purple600,
    lineHeight: '18px',
    marginBottom: 24,
  };
});

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: !isMobile && 64,
      paddingTop: 0,
    };
  }
);

interface ErrorModalProps {
  onBackdropPress: () => void;
  onPress: () => void;
  isOpen: boolean;
  title: string;
  subtitle: string;
}

const ErrorModal = ({ onBackdropPress, onPress, isOpen, title, subtitle }: ErrorModalProps) => (
  <ModalContainer onBackdropPress={onBackdropPress} hide={!isOpen}>
    <ContentWrapper>
      <CalendarSyncError />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <ButtonV2 text="Try again" onPress={onPress} />
    </ContentWrapper>
  </ModalContainer>
);

export default ErrorModal;
