import { ReactNode } from 'react';
import {
  Standard,
  TouchableView,
  styled,
  View,
  ErrorTooltipIcon,
  WarningTooltipIcon,
  RecurrenceIcon,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import Lottie from 'react-lottie';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { DEFAULT_POLLING_TIME } from '../../../../../../../hooks/notes/useMutationGenerateSessionSummary';
import StarLoaderData from '../../../../../../../assets/animations/starLoader.json';

const Label = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.slateGrey,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  };
});

const ActionLabel = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    color: colors.accessibilityGreenDark,
    textAlign: 'center',
    marginLeft: 6,
  };
});

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    width: 347,
    minHeight: 80,
    padding: 12,
    borderRadius: 8,
    border: `1px solid ${colors.darkBlue150}`,
    background: colors.permaPhantomWhite,
  };
});

const InnerContainer = styled(View)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
    paddingTop: 39,
    paddingBottom: 48,
  };
});

export type SmartNoteState = 'loading' | 'timeout' | 'error' | 'success' | null;

interface SmartNotePlaceholderProps {
  text?: string | null;
  smartNoteState: SmartNoteState;
  handleSetSmartNoteState: (value: SmartNoteState) => void;
  handleAddSmartNote: () => void;
}

interface GetPlaceHolderResponse {
  label: string;
  icon: ReactNode;
  action?: () => void;
  actionLabel?: string;
  actionIcon?: ReactNode;
}

const SmartNotePlaceholder = ({
  text,
  smartNoteState,
  handleSetSmartNoteState,
  handleAddSmartNote,
}: SmartNotePlaceholderProps) => {
  const { colors } = useEmotionTheme();
  const {
    aiDocumentation: { loadingTimeoutInSeconds } = {
      loadingTimeoutInSeconds: DEFAULT_POLLING_TIME,
    },
  } = useFlags();

  const getPlaceHolder = (): GetPlaceHolderResponse | null => {
    switch (smartNoteState) {
      case 'loading':
        return {
          label: `Sit back and relax.\nThis can take up to ${loadingTimeoutInSeconds} seconds.`,
          icon: (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: StarLoaderData,
              }}
              height={66}
              width={66}
            />
          ),
        };
      case 'timeout':
        return {
          label: "We're having trouble writing\na Smart Note right now.",
          icon: <WarningTooltipIcon />,
          action: () => handleAddSmartNote(),
          actionLabel: 'Try again',
          actionIcon: <RecurrenceIcon color={colors.accessibilityGreenDark} />,
        };
      case 'error':
        return {
          label: 'Something is wrong on our end. Please\nwrite this summary from scratch.',
          icon: <ErrorTooltipIcon />,
          action: () => handleSetSmartNoteState(null),
          actionLabel: 'Okay',
        };
      default:
        return null;
    }
  };

  const placeholder = getPlaceHolder();

  if (!placeholder) {
    return null;
  }

  return (
    <Container>
      <Standard style={{ textAlign: 'left' }}>{text}</Standard>
      <InnerContainer>
        {placeholder.icon}
        <Label>{placeholder.label}</Label>
        {placeholder.action && (
          <TouchableView onPress={placeholder.action} row style={{ marginTop: 6 }}>
            {placeholder.actionIcon}
            <ActionLabel>{placeholder.actionLabel}</ActionLabel>
          </TouchableView>
        )}
      </InnerContainer>
    </Container>
  );
};

export default SmartNotePlaceholder;
