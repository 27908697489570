import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../utils/apiWrapper';

const QUERY_KEY = 'getConditions';
interface Condition {
  id: number;
  value: string;
  label: string;
  isBillable: boolean;
}
const fetchConditions = () => async () => {
  const { data } = await apiWrapper.get(`${apiHelper().apiEndpoint}/api/v1/clinical/conditions`);
  return data.data.map((item) => {
    return {
      id: item.id,
      value: item.id,
      label: item.condition,
      isBillable: item.isBillable,
    };
  });
};

const useQueryConditions = () =>
  useQuery<Array<Condition>, Error>({
    queryKey: QUERY_KEY,
    queryFn: fetchConditions(),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryConditions;
