import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import { therapistCalendarSyncPreferences } from './queryKeys';
import { OAuthProviderName } from './useQueryCalendarSyncStatus';

interface TherapistSyncPreferencesParams {
  therapistUserID: string;
  provider: OAuthProviderName;
}

interface TherapistSyncPreferencesResponse {
  syncBookings: boolean;
  syncAvailabilities: boolean;
}

const fetchTherapistCalendarSyncPreferences =
  (params: TherapistSyncPreferencesParams) => async () => {
    const { therapistUserID, provider } = params;
    const { data } = await apiWrapper.get(
      `${
        apiHelper().apiEndpoint
      }/v2/therapists/${therapistUserID}/provider/${provider}/calendar-sync/sync-preferences`
    );
    return data.data;
  };

const useQueryTherapistSyncPreferences = (params: TherapistSyncPreferencesParams) =>
  useQuery<TherapistSyncPreferencesResponse, Error>({
    queryKey: therapistCalendarSyncPreferences(),
    queryFn: fetchTherapistCalendarSyncPreferences(params),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(params.therapistUserID),
  });

export default useQueryTherapistSyncPreferences;
