import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  styled,
  TextDS,
  truncateTextMaxLinesStyle,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import { MessageSmall, Clock } from '@talkspace/react-toolkit/src/designSystems/icons';

import { AutomaticMessageFormValues, TherapistAutomaticMessage } from '../../../types/account';
import AutomaticMessageForm from './AutomaticMessageForm';
import useMutationUpdateAutomaticMessage from '../../../hooks/account/useMutationUpdateAutomaticMessage';
import { getUserData } from '../../../utils/token';
import ActionToast from './ActionToast';

const MainView = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    borderRadius: spacing('space150'),
    backgroundColor: colorRoles.surfaces.defaultSubtle,
    gap: spacing('space050'),
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    paddingTop: spacing('space150'),
    paddingRight: spacing('space200'),
    paddingBottom: spacing('space150'),
    paddingLeft: spacing('space200'),
  };
});

const HeaderView = styled(View)(
  ({
    theme: {
      window: { isMobile },
      spacing,
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: isMobile ? spacing('space200') : 0,
      height: 24,
    };
  }
);

const MessageView = styled(View)(
  ({
    theme: {
      spacing,
      window: { isMobile },
      colorRoles,
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      paddingTop: spacing('space200'),
      paddingBottom: isMobile ? spacing('space200') : 0,
      borderBottom: `1px solid ${colorRoles.borders.borderDefault}`,
    };
  }
);

const MessageContentView = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingBottom: spacing('space200'),
    flex: 1,
    minWidth: 0,
  };
});

const EditButton = styled(Button)(({ theme: { spacing } }) => {
  return {
    width: '104px !important',
    height: '32px !important',
    marginLeft: spacing('space400'),
  };
});

const TriggerView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    gap: spacing('space200'),
    paddingTop: spacing('space200'),
    paddingBottom: spacing('space200'),
  };
});

const ButtonsView = styled(View)<{ isVisible: boolean }>(({ isVisible, theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: spacing('space100'),
    overflow: 'hidden',
    maxHeight: isVisible ? 200 : 0,
    paddingTop: isVisible ? spacing('space200') : 0,
    paddingBottom: isVisible ? spacing('space100') : 0,
    opacity: isVisible ? 1 : 0,
    transition:
      'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out',
  };
});

const ActionButton = styled(Button)({
  width: '104px !important',
  height: '40px !important',
});

interface Props {
  message: TherapistAutomaticMessage;
}

export default function AutomaticMessageItem({ message }: Props) {
  const {
    window: { isMobile },
    spacing,
  } = useEmotionTheme();

  const [formValues, setFormValues] = useState<AutomaticMessageFormValues>({
    customMessage: message.customMessage,
    enabled: message.enabled,
  });

  useEffect(() => {
    setFormValues((prev) => {
      return {
        ...prev,
        customMessage: message.customMessage,
        enabled: message.enabled,
      };
    });
  }, [message]);

  const [customMessage, setCustomMessage] = useState(formValues.customMessage);

  useEffect(() => {
    setCustomMessage(formValues.customMessage);
  }, [formValues.customMessage]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutateAsync: updateAutomaticMessage, isLoading: isSaving } =
    useMutationUpdateAutomaticMessage();

  const handleEnableAutomationChange = (newValue: boolean) => {
    setFormValues((prev) => {
      return { ...prev, enabled: newValue };
    });
  };

  const handleCustomMessageChange = (newValue: string) => {
    setCustomMessage(newValue);
  };

  const handleReset = () => {
    setCustomMessage(message.defaultMessage);
  };

  const handleFormModalClose = () => {
    setCustomMessage(formValues.customMessage);
    setIsModalOpen(false);
  };

  const handleFormModalSubmit = () => {
    setFormValues((prev) => {
      return { ...prev, customMessage };
    });
    setIsModalOpen(false);
  };

  const handleCancelPress = () => {
    setFormValues((prev) => {
      return {
        ...prev,
        customMessage: message.customMessage,
        enabled: message.enabled,
      };
    });
  };

  const handleSavePress = async () => {
    try {
      const { id: therapistUserID } = getUserData();

      await updateAutomaticMessage({
        therapistUserID,
        messageID: message.id,
        data: formValues,
      });

      toast(
        <ActionToast variant={formValues.enabled ? 'ON' : 'OFF'} messageType={message.type} />,
        {
          autoClose: 3000,
          position: 'top-center',
        }
      );
    } catch (e) {
      toast(
        <div
          className="toaster toaster-error"
          style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
        >
          Unexpected error occurred
        </div>,
        {
          autoClose: 3000,
        }
      );
    }
  };

  const isStatePristine =
    message.enabled === formValues.enabled && message.customMessage === formValues.customMessage;

  return (
    <>
      <MainView>
        <View style={{ gap: 2, alignItems: isMobile ? 'flex-start' : 'stretch' }}>
          <HeaderView>
            <TextDS variant="headingMd">{message.type}</TextDS>

            {!isMobile && (
              <Checkbox
                label="Enable automation"
                isChecked={formValues.enabled}
                setIsChecked={handleEnableAutomationChange}
                dataQa={`enableAutomationCheckbox-${message.type}`}
                isLabelOnRight={isMobile}
              />
            )}
          </HeaderView>

          <TextDS variant="bodySm">{message.description}</TextDS>

          {isMobile && (
            <Checkbox
              label="Enable automation"
              isChecked={formValues.enabled}
              setIsChecked={handleEnableAutomationChange}
              dataQa={`enableAutomationCheckbox-${message.type}`}
              isLabelOnRight={isMobile}
              containerStyle={{ marginTop: 26 }}
            />
          )}
        </View>

        <MessageView>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ marginRight: spacing('space200') }}>
              <MessageSmall colorType="subtle" variant="filled" />
            </View>

            <MessageContentView>
              <TextDS variant="headingSm">Message</TextDS>

              <TextDS variant="bodySm" style={{ ...truncateTextMaxLinesStyle(2) }}>
                {formValues.customMessage}
              </TextDS>
            </MessageContentView>
          </View>

          <EditButton
            variant="secondary"
            text="Edit"
            dataQa={`editMessageButton-${message.type}`}
            onPress={() => setIsModalOpen(true)}
            sizeDS="slim"
          />
        </MessageView>

        <TriggerView>
          <Clock colorType="subtle" variant="filled" />
          <View style={{ flex: 1 }}>
            <TextDS variant="headingSm">When will this message be sent?</TextDS>
            <TextDS variant="bodySm">
              New clients will receive this message automatically if no messages have been
              exchanged.
            </TextDS>
          </View>
        </TriggerView>

        <ButtonsView isVisible={!isStatePristine}>
          <ActionButton
            text="Cancel"
            variant="tertiary"
            dataQa={`cancelButton-${message.type}`}
            onPress={handleCancelPress}
            disabled={isSaving}
          />

          <ActionButton
            text="Save"
            dataQa={`saveButton-${message.type}`}
            onPress={handleSavePress}
            disabled={isSaving}
            isLoading={isSaving}
          />
        </ButtonsView>
      </MainView>

      <AutomaticMessageForm
        isOpen={isModalOpen}
        initialValue={customMessage}
        dataTags={message.dataTags}
        title={message.type}
        onClose={handleFormModalClose}
        onSubmit={handleFormModalSubmit}
        onChange={handleCustomMessageChange}
        onResetPress={handleReset}
        canReset={customMessage !== message.defaultMessage}
      />
    </>
  );
}
