import { useQuery, UseQueryOptions } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

import { smartInsightsQueryKey } from './queryKeys';
import { SmartInsightsResponse } from '../types/clinical';

const fetchSmartInsights = (roomID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/smart-insights`
  );

  return data.data;
};

export default function useQuerySmartInsights(
  roomID: number,
  options: UseQueryOptions<SmartInsightsResponse, Error, SmartInsightsResponse> = {}
) {
  return useQuery<SmartInsightsResponse, Error>({
    queryKey: smartInsightsQueryKey(roomID),
    queryFn: fetchSmartInsights(roomID),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });
}
