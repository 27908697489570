import { useMutation, useQueryClient } from 'react-query';

import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { OAuthProviderName } from './useQueryCalendarSyncStatus';
import { calendarSyncStatus } from './queryKeys';

interface PostOAuthRevokeParams {
  userID: number;
  provider: OAuthProviderName;
}

const postOAuthRevoke = async (params: PostOAuthRevokeParams): Promise<void> => {
  const { userID, provider } = params;

  await apiWrapper.post(`${apiHelper().authAPIEndpoint}/v1/oauth2/oauth-therapist/revoke`, {
    provider,
    userID,
  });
};

const useMutationOAuthRevoke = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, PostOAuthRevokeParams>(postOAuthRevoke, {
    onSuccess: (_, { provider }) => {
      queryClient.invalidateQueries(calendarSyncStatus(provider));
    },
  });
};

export default useMutationOAuthRevoke;
