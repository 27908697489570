import { TextDS, View, Checkbox, useEmotionTheme, CheckV2 } from '@talkspace/react-toolkit';
import { useEffect, useState } from 'react';
import ButtonV2 from '@talkspace/react-toolkit/src/designSystems/components/Button/ButtonV2';
import { useFlags } from 'launchDarkly/FlagsProvider';
import styled from '@/core/styled';
import useMutationCalendarSync from '../../../../hooks/calendarSync/useMutationCalendarSync';
import { getUserData } from '../../../../utils/token';
import { OAuthProviderName } from '../../../../hooks/calendarSync/useQueryCalendarSyncStatus';
import useQueryTherapistSyncPreferences from '../../../../hooks/calendarSync/useQueryTherapistSyncPreferences';

const Container = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    marginTop: spacing('space300'),
    marginBottom: spacing('space300'),
    backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
    padding: '12px 16px',
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    borderRadius: spacing('space100'),
    gap: spacing('space200'),
    display: 'flex',
    flexDirection: 'column',
  };
});

const CalendarPreferenceSync = ({ oAuthProvider }: { oAuthProvider: OAuthProviderName }) => {
  const { colors } = useEmotionTheme();
  const {
    calendarSync: { syncBookingsON, syncAvailabilityON },
  } = useFlags();
  const [isEditMode, setIsEditMode] = useState(false);
  const [syncAvailabilities, setSyncAvailabilities] = useState(syncAvailabilityON);
  const [syncBookings, setSyncBookings] = useState(syncBookingsON);
  const therapistID = getUserData().id;

  const { mutate: postCalendarSync, isLoading: isPostCalendarSyncLoading } =
    useMutationCalendarSync();

  const { data: syncPreferences } = useQueryTherapistSyncPreferences({
    therapistUserID: therapistID,
    provider: oAuthProvider,
  });

  useEffect(() => {
    if (syncPreferences) {
      setSyncAvailabilities(syncPreferences.syncAvailabilities);
      setSyncBookings(syncPreferences.syncBookings);
    }
  }, [syncPreferences]);

  const handleSave = () => {
    setIsEditMode(false);
    postCalendarSync({
      therapistID,
      provider: oAuthProvider,
      syncBookings,
      syncAvailabilities,
    });
  };

  return (
    <Container>
      <View row justify="space-between">
        <TextDS variant="headingSm">What would you like to sync?</TextDS>
        {isEditMode ? (
          <View row style={{ gap: 10 }}>
            <ButtonV2
              stretch={false}
              variant="secondary"
              text="Cancel"
              onPress={() => setIsEditMode(false)}
              disabled={isPostCalendarSyncLoading}
            />
            <ButtonV2
              disabled={isPostCalendarSyncLoading}
              isLoading={isPostCalendarSyncLoading}
              stretch={false}
              variant="primary"
              text="Save"
              onPress={handleSave}
            />
          </View>
        ) : (
          <ButtonV2
            stretch={false}
            variant="tertiary"
            text="Edit"
            onPress={() => setIsEditMode(true)}
          />
        )}
      </View>

      {syncBookingsON && (
        <Checkbox
          isChecked={syncBookings}
          label="Confirmed and unconfirmed sessions"
          setIsChecked={() => setSyncBookings((prev) => !prev)}
          isDisabled={!isEditMode}
          isLabelOnRight
          stretch={false}
          checkboxStyle={{ height: 24, width: 24 }}
          containerStyle={{ marginBottom: 12, marginTop: -6 }}
          labelStyle={{ color: colors.black, fontWeight: 400, alignSelf: 'unset' }}
          checkComponent={<CheckV2 />}
          alignCenter
        />
      )}

      {syncAvailabilityON && (
        <Checkbox
          isChecked={syncAvailabilities}
          label="Availability"
          setIsChecked={() => setSyncAvailabilities((prev) => !prev)}
          isDisabled={!isEditMode}
          isLabelOnRight
          stretch={false}
          checkboxStyle={{ height: 24, width: 24 }}
          labelStyle={{ color: colors.black, fontWeight: 400, alignSelf: 'unset' }}
          checkComponent={<CheckV2 />}
          alignCenter
        />
      )}
    </Container>
  );
};

export default CalendarPreferenceSync;
