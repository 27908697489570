import { View, Announcement, styled, EmotionThemeProvider } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';

import useMutationFeatureViews from 'ts-frontend/hooks/featureViews/useMutationFeatureViews';
import useQueryFeatureViews from 'ts-frontend/hooks/featureViews/useQueryFeatureViews';
import Lottie from 'react-lottie';
import AUTOMATIC_WELCOME_MESSAGE_FEATURE_NAME from 'ts-frontend/constants/featureViews';
import { useHistory } from '@/core/routerLib';
import ssoHelper from '../../../modules/utils/sso';
import AutomaticMessagesAnnouncementAnimation from './AutomaticMessagesAnnouncementAnimations.json';

const TopSectionWrapper = styled(View)(({ theme: { colorRoles } }) => {
  return {
    height: 176,
    width: 368,
    backgroundColor: colorRoles.surfaces.decorativeBalanceBlue,
    paddingTop: 20,
  };
});

const AutomaticMessagesAnnouncementModal = ({ therapistID }: { therapistID: string }) => {
  const { automaticWelcomeMessage: isAutomaticWelcomeMessageActive } = useFlags();
  const history = useHistory();

  const { data: featureViewsData } = useQueryFeatureViews(therapistID);

  const { mutate: markFeatureViewed } = useMutationFeatureViews();

  const markModalViewed = () => {
    markFeatureViewed({ featureName: AUTOMATIC_WELCOME_MESSAGE_FEATURE_NAME, userID: therapistID });
  };

  const isAnnouncementOpen =
    isAutomaticWelcomeMessageActive &&
    !!featureViewsData &&
    !featureViewsData[AUTOMATIC_WELCOME_MESSAGE_FEATURE_NAME];

  return (
    <EmotionThemeProvider version="2.0.0">
      <Announcement
        tagPosition="top"
        isOpen={isAnnouncementOpen}
        isHorizontal={false}
        title="Save time by setting up automated welcome messages"
        subtitle="We'll give you a customizable template to help you create a warm, personalized message that covers all the key information your clients need to begin."
        tagText="New!"
        illustration={
          <TopSectionWrapper>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: AutomaticMessagesAnnouncementAnimation,
              }}
              height="100%"
              width="100%"
            />
          </TopSectionWrapper>
        }
        onClose={markModalViewed}
        primaryButtonText="Get started"
        onPrimaryButtonClick={() => {
          markModalViewed();
          history.push('/my-account?scrollTo=automaticMessages');
        }}
        secondaryButtonText="Learn more"
        onSecondaryButton={() => {
          markModalViewed();
          ssoHelper.openZendesk('/hc/en-us/articles/30838802915099');
        }}
      />
    </EmotionThemeProvider>
  );
};

export default AutomaticMessagesAnnouncementModal;
