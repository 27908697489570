import { createEditor, Descendant } from 'slate';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { useCallback, useMemo, useState, KeyboardEvent } from 'react';
import { withHistory } from 'slate-history';
import {
  Button,
  ChevronLeft,
  EmotionStyle,
  Modal,
  styled,
  TextDS,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import { fontWeight } from '@talkspace/react-toolkit/src/designSystems/tokens';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { ArrowRotateRight, XMarkLarge } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useKeyboardStatus } from 'ts-ionic/plugins/keyboard';

import { DataTagStatus } from './types';
import useHighlightDataTags from './useHighlightDataTags';
import DataTagTooltip from './DataTagTooltip';
import UpperFormBanner from './UpperFormBanner';
import LowerFormBanner from './LowerFormBanner';
import { deserialize, serialize } from './utils';
import { AutomaticMessageDataTag } from '../../../../types/account';

const HEADER_HEIGHT = 50;

const FOOTER_HEIGHT_DESKTOP = 64;

const FOOTER_HEIGHT_MOBILE = 132;

const HeaderView = styled(View)(({ theme: { colorRoles } }) => {
  return {
    height: HEADER_HEIGHT,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
    position: 'absolute',
    backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
    zIndex: 10,
  };
});

const EditorView = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    borderRadius: spacing('space050'),
    marginLeft: spacing('space200'),
    marginRight: spacing('space200'),
  };
});

const StyledEditable = styled(Editable)(({ theme: { spacing } }) => {
  return {
    outline: 'none',
    padding: spacing('space200'),
  };
});

const EditorText = styled('span')<{ dataTagStatus?: DataTagStatus; isHovering?: boolean }>(
  ({ theme: { colorRoles }, dataTagStatus, isHovering }) => {
    return {
      fontFamily: "'Roboto', sans-serif",
      color: colorRoles.typography.textDefault,
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: fontWeight.regular,
      letterSpacing: 0.123,
      backgroundColor: isHovering ? colorRoles.surfaces.infoSubtle : undefined,
      ...(dataTagStatus
        ? {
            color:
              dataTagStatus === 'invalid_format' || dataTagStatus === 'unknown_tag'
                ? colorRoles.typography.textCriticalDefault
                : colorRoles.icons.iconInfoDefault,
            fontWeight: fontWeight.semibold,
          }
        : {}),
      ...(isHovering
        ? {
            backgroundColor: colorRoles.surfaces.infoSubtle,
            color: colorRoles.typography.textDefault,
          }
        : {}),
    };
  }
);

const ModalContentView = styled(View)<{ isKeyboardOpen: boolean }>(
  ({
    isKeyboardOpen,
    theme: {
      spacing,
      window: { isMobile },
    },
  }) => {
    const mobileOffset = HEADER_HEIGHT + FOOTER_HEIGHT_MOBILE + 100;
    const mobileHeight = isKeyboardOpen ? 400 : `calc(100vh - ${mobileOffset}px)`;
    return {
      gap: spacing('space150'),
      maxWidth: 465,
      height: isMobile ? mobileHeight : 404,
      overflowY: 'auto',
      marginTop: HEADER_HEIGHT + spacing('space300'),
      paddingBottom: spacing('space300'),
    };
  }
);

const TitleView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginLeft: spacing('space200'),
    height: 32,
  };
});

const ResetButton = styled(Button)<{ isVisible: boolean }>(({ isVisible }) => {
  return {
    maxHeight: isVisible ? 200 : 0,
    opacity: isVisible ? 1 : 0,
    transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
  };
});

const FooterView = styled(View)(
  ({
    theme: {
      colorRoles,
      window: { isMobile },
    },
  }) => {
    return {
      position: 'absolute',
      height: isMobile ? FOOTER_HEIGHT_MOBILE : FOOTER_HEIGHT_DESKTOP,
      bottom: 0,
      right: 0,
      width: '100%',
      borderTop: `0.5px solid ${colorRoles.borders.borderDefault}`,
      backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
    };
  }
);

const ButtonsView = styled(View)(
  ({
    theme: {
      window: { isMobile },
      spacing,
    },
  }) => {
    return {
      flexDirection: isMobile ? 'column-reverse' : 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: isMobile ? spacing('space050') : spacing('space200'),
      padding: spacing('space200'),
    };
  }
);

const ResetWarningView = styled(View)(({ theme: { spacing } }) => {
  return {
    marginTop: HEADER_HEIGHT + spacing('space300'),
    padding: spacing('space150'),
    gap: spacing('space150'),
  };
});

const modalPanelStyle = {
  paddingTop: 0,
  paddingLeft: 0,
  paddingRight: 0,
  width: '100%',
  alignItems: 'center',
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  initialValue: string;
  title: string;
  onChange: (value: string) => void;
  onResetPress: () => void;
  canReset: boolean;
  dataTags: AutomaticMessageDataTag[];
  editorStyle?: EmotionStyle;
}

export default function AutomaticMessageForm({
  isOpen,
  onClose,
  onSubmit,
  initialValue,
  title,
  onChange,
  onResetPress,
  canReset,
  dataTags,
  editorStyle = {},
}: Props) {
  const {
    colorRoles,
    window: { isMobile },
  } = useEmotionTheme();

  const { isKeyboardOpen } = useKeyboardStatus();

  const [hoveredDataTag, setHoveredDataTag] = useState('');
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const [showResetWarning, setShowResetWarning] = useState(false);

  const dataTagCodes = useMemo(() => dataTags.map((it) => it.code), [dataTags]);

  const { highlightDataTags, editorError, invalidTokens, clearEditorError } =
    useHighlightDataTags(dataTagCodes);

  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  const renderLeaf = useCallback(
    ({ attributes, leaf, children }) =>
      leaf.dataTagStatus === 'valid' ? (
        <EditorText
          {...attributes}
          dataTagStatus={leaf.dataTagStatus}
          onMouseEnter={() => {
            // Calling "deselect" here is necessary to help clear any temporary
            // selection Slate might be holding and prevent it from trying to reference the highlighted text.
            // Otherwise, run-time exceptions sometimes occur
            ReactEditor.deselect(editor);
            setHoveredDataTag(leaf.dataTag);
          }}
          onMouseLeave={() => setHoveredDataTag('')}
          isHovering={hoveredDataTag === leaf.dataTag}
          style={{ cursor: 'pointer' }}
        >
          {children}

          <DataTagTooltip
            isVisible={hoveredDataTag === leaf.dataTag}
            dataTag={dataTags.find((it) => it.code === hoveredDataTag)}
          />
        </EditorText>
      ) : (
        <EditorText {...attributes} dataTagStatus={leaf.dataTagStatus}>
          {children}
        </EditorText>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataTags, editor, editorError, hoveredDataTag]
  );

  const handleChange = (value: Descendant[]) => {
    const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
    if (!isAstChange) {
      return;
    }

    setShowErrorBanner(false);
    clearEditorError();
    onChange(serialize(value));
  };

  const handleResetPress = () => {
    onResetPress();
    clearEditorError();
    setShowErrorBanner(false);
    setShowResetWarning(false);
  };

  const handleBackPress = () => {
    if (showResetWarning) {
      setShowResetWarning(false);
      return;
    }

    onClose();
  };

  const handleClosePress = () => {
    setShowResetWarning(false);
    clearEditorError();
    setShowErrorBanner(false);
    onClose();
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'z') {
        event.preventDefault();
        if (event.shiftKey) {
          editor.redo();
        } else {
          editor.undo();
        }
      }
    },
    [editor]
  );

  const handleSubmit = async () => {
    if (editorError) {
      setShowErrorBanner(true);
      return;
    }

    onSubmit();
  };

  const initialValueParsed = useMemo(() => deserialize(initialValue), [initialValue]);

  return (
    <Modal
      isVisible={isOpen}
      onBackdropPress={handleClosePress}
      underlayStyle={{ alignItems: 'center' }}
    >
      <Modal.Panel
        onBackdropPress={handleClosePress}
        showCloseButton={false}
        contentViewStyle={modalPanelStyle}
        minHeight={220}
        wrapperStyle={{ overflow: 'hidden' }}
      >
        <HeaderView>
          <TextDS variant="headingSm">Edit automatic message</TextDS>

          {(showResetWarning || isMobile) && (
            <IconButton
              dataQa="backButton"
              Icon={<ChevronLeft colorType="brand" />}
              onPress={handleBackPress}
              style={{ position: 'absolute', top: 8, left: 8 }}
            />
          )}

          {!isMobile && (
            <IconButton
              dataQa="closeButton"
              Icon={<XMarkLarge colorType="brand" />}
              onPress={handleClosePress}
              style={{ position: 'absolute', top: 8, right: 8 }}
            />
          )}
        </HeaderView>

        {showResetWarning ? (
          <ResetWarningView>
            <TextDS variant="headingMd">Reset to default?</TextDS>
            <TextDS variant="bodySm">
              This will undo any changes you’ve ever made to this script. This cannot be undone.
            </TextDS>
          </ResetWarningView>
        ) : (
          <ModalContentView isKeyboardOpen={isKeyboardOpen}>
            <TitleView>
              <TextDS variant="headingMd">{title}</TextDS>

              <ResetButton
                isVisible={canReset}
                variant="tertiary"
                text="Reset to default"
                dataQa="resetToDefaultButton"
                onClick={() => setShowResetWarning(true)}
                sizeDS="slim"
                stretch={false}
                Icon={() => <ArrowRotateRight colorType="brand" />}
              />
            </TitleView>

            <EditorView>
              <UpperFormBanner
                isError={showErrorBanner}
                invalidTokens={invalidTokens}
                dataTags={dataTags.map((it) => it.code)}
              />

              <Slate initialValue={initialValueParsed} editor={editor} onChange={handleChange}>
                <StyledEditable
                  decorate={highlightDataTags}
                  renderLeaf={renderLeaf}
                  style={editorStyle}
                  onKeyDown={handleKeyDown}
                />
              </Slate>

              <LowerFormBanner />
            </EditorView>
          </ModalContentView>
        )}

        <FooterView style={{ display: isKeyboardOpen ? 'none' : 'flex' }}>
          <ButtonsView>
            {showResetWarning ? (
              <>
                <Button
                  text="Nevermind"
                  variant="tertiary"
                  dataQa="nevermindButton"
                  onPress={() => setShowResetWarning(false)}
                />

                <Button
                  text="Reset"
                  dataQa="confirmResetButton"
                  onPress={handleResetPress}
                  style={{ backgroundColor: colorRoles.surfaces.criticalBoldDefault }}
                />
              </>
            ) : (
              <>
                <Button
                  text="Cancel"
                  variant="tertiary"
                  dataQa="cancelButton"
                  onPress={handleClosePress}
                />
                <Button text="Done" dataQa="doneButton" onPress={handleSubmit} />
              </>
            )}
          </ButtonsView>
        </FooterView>
      </Modal.Panel>
    </Modal>
  );
}
