import { Button, TextDS, View } from '@talkspace/react-toolkit';
import { WebPageError } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { ArrowRotateRight } from '@talkspace/react-toolkit/src/designSystems/icons';

interface Props {
  onTryAgainPress: () => void;
}

export default function SmartInsightsError({ onTryAgainPress }: Props) {
  return (
    <>
      <View align="center">
        <WebPageError width={48} height={48} />
        <TextDS variant="bodySm" colorRole="textSubtle" style={{ width: 247, textAlign: 'center' }}>
          We’re having trouble loading smart insights right now
        </TextDS>
      </View>

      <View>
        <Button
          text="Try again"
          Icon={() => <ArrowRotateRight colorType="inverse" />}
          sizeDS="slim"
          dataQa="tryAgainButton"
          onPress={onTryAgainPress}
        />
      </View>
    </>
  );
}
