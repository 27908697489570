import moment from 'moment';
import { TextDS, View } from '@talkspace/react-toolkit';
import { gender } from '@talkspace/configs';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib';
import { getActiveCustomerName } from '../../../../utils/customerHelper';
import useQueryPresentingProblems from '../../../../hooks/useQueryPresentingProblems';
import useQueryClientConditions from '../../../../hooks/useQueryClientConditions';
import useQueryEmergencyContact from '../../../../hooks/useQueryEmergencyContact';
import useQueryCustomerInformation from '../../../../hooks/useQueryCustomerInformation';
import useQueryParticipants from '../../../../hooks/useQueryParticipants';

export default function ClientInfo() {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: roomDetails } = useQueryRoomDetails(Number(roomID));

  const { data: presentingProblems = [] } = useQueryPresentingProblems(
    roomID,
    roomDetails?.clientUserID
  );

  const { data: conditions = [] } = useQueryClientConditions(roomDetails?.clientUserID);

  const { data: emergencyContact } = useQueryEmergencyContact(roomDetails?.clientUserID);

  const { data: customerInformation } = useQueryCustomerInformation(roomDetails?.clientUserID);

  const { data: participants } = useQueryParticipants(Number(roomID));

  const customerNickname = getActiveCustomerName(customerInformation || {}, participants);

  return (
    <View>
      <TextDS variant="headingMd" style={{ marginBottom: 4 }}>
        {emergencyContact?.firstName} {emergencyContact?.lastName} ({customerNickname})
      </TextDS>
      <View>
        <TextDS variant="bodySm" colorRole="textSubtle">
          {gender[customerInformation?.gender as keyof typeof gender]},{' '}
          {moment().diff(moment(customerInformation?.dateOfBirth), 'years')}
        </TextDS>

        <TextDS variant="bodySm" colorRole="textSubtle">
          {presentingProblems.length > 1
            ? `Presenting problems: ${presentingProblems.map((it) => it.label).join(', ')}`
            : `Presenting problem: ${presentingProblems[0]?.label || 'none indicated'}`}
        </TextDS>

        {conditions.length ? (
          <TextDS variant="bodySm" colorRole="textSubtle">
            {conditions.length > 1
              ? `Diagnoses: ${conditions.map((it) => it.value).join(', ')}`
              : `Diagnosis: ${conditions[0].value}`}
          </TextDS>
        ) : null}
      </View>
    </View>
  );
}
