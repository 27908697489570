import {
  Link,
  styled,
  Tag,
  TextDS,
  View,
  WithHoverProps,
  withHover,
} from '@talkspace/react-toolkit';
import { elevation2Style } from '@talkspace/react-toolkit/src/designSystems/styles/elevation';
import ssoHelper from '@/utils/sso';

const HoverableText = styled(TextDS)<{ isHovering: boolean }>(
  ({ isHovering, theme: { colorRoles } }) => {
    return {
      textDecoration: 'underline dotted',
      backgroundColor: isHovering ? colorRoles.surfaces.infoSubtle : 'unset',
      cursor: 'pointer',
    };
  }
);

const TooltipView = styled(View)(({ theme: { spacing, colorRoles } }) => {
  return {
    width: 293,
    height: 137,
    padding: spacing('space200'),
    borderRadius: 12,
    position: 'absolute',
    backgroundColor: colorRoles.surfaces.defaultSubtle,
    top: 'auto',
    right: 'auto',
    bottom: 20,
    gap: spacing('space050'),
    ...elevation2Style,
  };
});

const TooltipTitleView = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
});

const StyledLink = styled(Link)(({ theme: { colorRoles } }) => {
  return {
    margin: 0,
    fontWeight: 'bold',
    color: colorRoles.typography.textBrandDefault,
    fontSize: 14,
    textDecoration: 'underline solid',
  };
});

const AnnouncementTooltip = ({ isHovering, ...rest }: WithHoverProps) => (
  <View {...rest} style={{ position: 'relative' }}>
    <HoverableText variant="bodySm" colorRole="textSubtle" inline isHovering={isHovering}>
      Smart Insights
    </HoverableText>

    {isHovering && (
      <TooltipView>
        <TooltipTitleView>
          <TextDS variant="headingMd">New! Smart Insights</TextDS>
          <Tag text="Beta" color="decorativeGratitudeGreen" />
        </TooltipTitleView>

        <TextDS variant="bodySm">
          We use HIPAA-compliant technology to draft a summary of your client based on assessment
          data and previous sessions
        </TextDS>

        <StyledLink
          onClick={() => ssoHelper.openZendesk('hc/en-us/articles/31304197952795')}
          text="How does this work?"
          target="_blank"
          dataQa="wowDoesThisWorkLink"
        />
      </TooltipView>
    )}
  </View>
);

export default withHover(AnnouncementTooltip);
