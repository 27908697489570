import { Button, TextDS, View } from '@talkspace/react-toolkit';
import StarsIcon from '@talkspace/react-toolkit/src/components/icons/StarsIcon';

export default function SmartInsightsUnavailable() {
  return (
    <>
      <TextDS variant="bodySm" colorRole="textSubtle" style={{ width: 247, textAlign: 'center' }}>
        Smart Insights will become available after your first session
      </TextDS>

      <View>
        <Button text="Generate insights" disabled Icon={StarsIcon} sizeDS="slim" />
      </View>
    </>
  );
}
