import { Path, Svg } from 'svgs';

const CalendarSyncError = ({ width = 136, height = 136 }) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="68" cy="68" r="68" fill="#F4F6FA" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.826 38.7842L96.8228 72.4419C104.24 75.4011 108.452 82.1768 108.831 89.2311C109.412 100.047 100.942 109.129 90.7224 110.135C81.4002 111.053 72.9873 105.11 70.1768 96.6142H37.3154C36.5699 96.6142 35.9656 96.01 35.9656 95.2645C35.9656 90.877 35.9637 85.3233 35.9617 79.4611C35.9557 61.9845 35.9487 41.7649 35.9887 41.5221C35.9904 41.5119 35.9919 41.4846 35.9941 41.4429C36.0209 40.9526 36.1569 38.4606 37.9999 37.9999C37.9999 37.9999 38.9999 37.8231 95.8624 37.8231H95.8649C96.3901 37.8231 96.826 38.2474 96.826 38.7842ZM90.144 71.047C91.6956 71.1141 93.2869 71.3503 94.9007 71.7727L94.9029 49.0296C92.8774 49.0431 82.6999 49.0086 39.8307 49.0273V91.8318C39.8307 92.0458 40.0053 92.2197 40.2193 92.2197H69.3069C68.4028 80.2466 78.0687 70.5246 90.144 71.047ZM94.9029 48.9541V48.9677C94.8923 48.9634 94.8911 48.9589 94.9029 48.9541ZM94.9031 47.1027L94.9038 39.7478L39.8307 39.923V47.1051C82.7143 47.1238 92.8823 47.0892 94.9031 47.1027ZM94.9031 47.1783C94.8912 47.1735 94.8924 47.1689 94.9031 47.1647V47.1783ZM89.8713 106.857C98.9919 106.857 106.386 99.2457 106.386 89.8569C106.386 80.4681 98.9919 72.8569 89.8713 72.8569C80.7507 72.8569 73.357 80.4681 73.357 89.8569C73.357 99.2457 80.7507 106.857 89.8713 106.857Z"
      fill="#2B2A2E"
    />
    <Path
      d="M62.1088 69.7558V54.6996C62.1088 54.169 61.6783 53.7385 61.1477 53.7385H45.2931C44.7625 53.7385 44.332 54.169 44.332 54.6996V69.7558C44.332 70.2864 44.7625 70.7169 45.2931 70.7169H61.1477C61.6783 70.7169 62.1088 70.2864 62.1088 69.7558ZM60.1866 68.7947H46.2543V55.6607H60.1866V68.7947Z"
      fill="#68768D"
    />
    <Path
      d="M87.0217 55.7609H68.0041C67.4735 55.7609 67.043 56.1914 67.043 56.722C67.043 57.2526 67.4735 57.6831 68.0041 57.6831H87.0217C87.5523 57.6831 87.9828 57.2526 87.9828 56.722C87.9828 56.1914 87.5523 55.7609 87.0217 55.7609Z"
      fill="#2B2A2E"
    />
    <Path
      d="M80.1506 65.1466H68.0041C67.4735 65.1466 67.043 65.5771 67.043 66.1077C67.043 66.6383 67.4735 67.0688 68.0041 67.0688H80.1506C80.6812 67.0688 81.1117 66.6383 81.1117 66.1077C81.1117 65.5771 80.6812 65.1466 80.1506 65.1466Z"
      fill="#2B2A2E"
    />
    <Path
      d="M94.0076 36.2313C94.5314 36.3041 95.0191 35.9389 95.0926 35.4129L96.09 28.2959C96.1638 27.7703 95.7972 27.2835 95.2715 27.2097C94.7534 27.1346 94.261 27.5037 94.1865 28.0281L93.1891 35.1451C93.1153 35.6719 93.482 36.1575 94.0076 36.2313Z"
      fill="#68768D"
    />
    <Path
      d="M99.7522 36.7017C101.325 35.5329 102.744 34.1701 103.972 32.6483C104.306 32.2353 104.241 31.6309 103.828 31.2968C103.415 30.9651 102.808 31.0277 102.477 31.4419C101.351 32.836 100.049 34.0862 98.6058 35.1575C98.1797 35.4741 98.0909 36.076 98.4075 36.5028C98.7248 36.9307 99.3286 37.0159 99.7522 36.7017Z"
      fill="#68768D"
    />
    <Path
      d="M99.3627 40.7814C99.4208 41.2872 99.8697 41.684 100.426 41.6274L104.794 41.1281C105.321 41.068 105.7 40.5912 105.64 40.0643C105.58 39.5362 105.11 39.1758 104.576 39.2184L100.209 39.7177C99.6812 39.7778 99.3026 40.2546 99.3627 40.7814Z"
      fill="#2B2A2E"
    />
    <Path
      d="M83.2152 83.2657C82.6572 83.8236 82.6572 84.729 83.2152 85.287L88.2054 90.2772L83.6703 95.1493C83.1123 95.7073 83.1123 96.6127 83.6703 97.1707C84.2283 97.7286 85.1337 97.7286 85.6916 97.1707L90.2267 92.2986L95.2182 97.2901C95.7762 97.8481 96.6816 97.8481 97.2396 97.2901C97.7975 96.7321 97.7975 95.8267 97.2396 95.2687L92.2481 90.2772L96.7838 85.4045C97.3418 84.8465 97.3418 83.9411 96.7838 83.3831C96.2258 82.8251 95.3204 82.8251 94.7624 83.3831L90.2267 88.2559L85.2365 83.2657C84.6785 82.7077 83.7731 82.7077 83.2152 83.2657Z"
      fill="#2B2A2E"
    />
  </Svg>
);

export default CalendarSyncError;
