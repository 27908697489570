import { useEffect, useRef } from 'react';

interface UseScrollIntoViewProps<T extends HTMLElement> {
  ref?: React.RefObject<T>;
  trigger: boolean;
  options?: ScrollIntoViewOptions;
  allSectionsLoaded: boolean;
}

function useScrollIntoView<T extends HTMLElement>({
  ref,
  trigger,
  options = {},
  allSectionsLoaded,
}: UseScrollIntoViewProps<T>): void {
  const scrollComplete = useRef(false);

  useEffect(() => {
    if (ref && trigger && allSectionsLoaded && ref.current && !scrollComplete.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', ...options });
      scrollComplete.current = true;
    }
  }, [trigger, allSectionsLoaded, ref, options]);
}

export default useScrollIntoView;
