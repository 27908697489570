import { useMutation, useQueryClient } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { smartInsightsQueryKey } from './queryKeys';

export interface CreateSmartInsightParams {
  roomID: number;
}

const createSmartInsight = async ({ roomID }: CreateSmartInsightParams): Promise<void> => {
  await apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/smart-insights`, {});
};

export default function useMutationCreateSmartInsight() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, CreateSmartInsightParams>(createSmartInsight, {
    onSuccess: async (_, { roomID }) => {
      await queryClient.invalidateQueries(smartInsightsQueryKey(roomID));
    },
  });
}
