import { EmotionTheme, EmotionStyle } from '@talkspace/react-toolkit';
import moment from 'moment';
import { BookingsV4Response } from '../../../hooks/liveSessions/useQueryProviderBookings';
import { BookingsByIDV4Response } from '../../../hooks/liveSessions/useQueryProviderBookingsByID';
import { WeekAvailability } from './types';
import { Availability } from '../../../hooks/availability/useQueryCalendarAvailabilityV3';

export const getCheckboxStyle = (colors: EmotionTheme['colors']) => {
  return {
    width: 19,
    height: 19,
    borderColor: colors.accessibilityGreenDark,
    background: 'transparent',
    borderRadius: 4,
    borderWidth: 2,
    '&:hover': {
      background: colors.permaGreyed,
    },
  };
};

export const getCheckboxLabelStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontWeight: 400,
    fontSize: 14,
    fontColor: colors.purple600,
  };
};

export const getRadioButtonStyle = (colors: EmotionTheme['colors']) => {
  return {
    innerCircleStyle: { background: colors.accessibilityDarkGreenBold, width: 11, height: 11 },
    circleStyle: {
      width: 19,
      height: 19,
      borderColor: colors.accessibilityDarkGreenBold,
      background: colors.permaWhite,
    },
  };
};

export const SCROLL_Y_WIDTH = 10;
export const MODAL_WIDTH = 460;
export const MODAL_HEIGHT = 200;

export const updateAllBookingState = (
  bookingID: string,
  bookingInState: BookingsV4Response[],
  bookingFromResponse: BookingsByIDV4Response
) => {
  const updatedBooking = bookingFromResponse || null;

  const updatedAllBookingData = bookingInState.filter((booking) => booking.bookingID !== bookingID);

  if (updatedBooking) {
    updatedAllBookingData.push(updatedBooking);
  }

  return updatedAllBookingData;
};

export const CALENDAR_ONBOARDING_COMPLETE = 'calendarOnboardingComplete';
export const DISPLAY_CALENDAR_SYNC = 'displayCalendarSync';
export const ONBOARDING_VIDEO_URL = 'https://vimeo.com/822856787/4df12a4ae1';

export const mobileViewOptions = [
  {
    label: 'Day',
    value: 'timeGridDay',
  },
  {
    label: '3 days',
    value: 'timeGridThreeDays',
  },
  {
    label: 'Week',
    value: 'timeGridWeek',
  },
];

export const isDateRangeInArray = (
  startTime: Date,
  endTime: Date,
  rangeArray: WeekAvailability[]
) => {
  for (const range of rangeArray) {
    const { start, end } = range;
    if (startTime >= start && startTime < end) {
      return true;
    }
    if (endTime > start && endTime <= end) {
      return true;
    }
  }

  return false;
};

export const getModalPositionStyle = (
  bookingChipPositionRef: Element | null,
  isMobile: boolean = false
) => {
  const positioningStyle: EmotionStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  if (!bookingChipPositionRef || isMobile) {
    return positioningStyle;
  }

  const elementRect = bookingChipPositionRef.getBoundingClientRect();

  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const spaceLeft = elementRect.left - scrollLeft;
  const spaceRight = window.innerWidth - (elementRect.right - scrollLeft);
  const modalPosition: any = {};

  modalPosition.top = Math.abs(elementRect.top) - MODAL_HEIGHT * 1.5;

  if (spaceRight > MODAL_WIDTH && spaceLeft < MODAL_WIDTH) {
    modalPosition.left = elementRect.right + scrollLeft;
  } else if (spaceLeft > MODAL_WIDTH) {
    modalPosition.left = elementRect.left - MODAL_WIDTH + scrollLeft;
  } else {
    modalPosition.left = elementRect.right + scrollLeft;
  }

  if (modalPosition.top < 0 || modalPosition.top + MODAL_HEIGHT * 1.8 > window.innerHeight) {
    return positioningStyle;
  }

  return modalPosition;
};

export const createAvailabitiltyItem = ({
  start,
  end,
  groupId = 'availability',
  title = 'availability',
  isSelected = false,
  isRecurring = true,
}) => {
  const minutes = Math.round((end.getTime() - start.getTime()) / 60000);
  return {
    display: 'background',
    isRecurring,
    groupId,
    title,
    start,
    end,
    minutes,
    isSelected,
    id: start.toString(),
  };
};

export const formatAvailabilities = (availabilitieList) => {
  const currentDate = new Date();
  return availabilitieList.reduce((acc, availability: Availability) => {
    const start = new Date(availability.start);
    const end = new Date(availability.end);
    if (end >= currentDate) {
      acc.push(
        createAvailabitiltyItem({
          start,
          end,
          title: availability.calendarEntityType,
          groupId: availability.calendarEntityType,
          isRecurring: availability.isRecurring,
        })
      );
    }
    return acc;
  }, []);
};

export const formatBookingsObjects = (bookingList, hideBookings: boolean) =>
  bookingList.map((booking) => {
    const start = new Date(booking.startTime);
    const end = new Date(start.getTime() + booking.creditMinutes * 60 * 1000);
    const title = 'booking';
    return {
      start,
      end,
      beginsAt: booking.startTime,
      endsAt: end.toISOString(),
      title,
      display: !hideBookings ? 'auto' : 'none',
      id: title + start.toString(),
      ...booking,
    };
  });

export const formatTimeOffs = (timeOffsList) =>
  timeOffsList.map((timeOff) => {
    const title = 'timeOff';
    return {
      start: moment(timeOff.startDate).toDate(),
      end: moment(timeOff.endDate).endOf('day').toDate(),
      title,
      display: 'background',
      id: title + timeOff.startDate.toString(),
    };
  });

export const getHourLater = (date: Date) => date.getTime() + 60 * 60 * 1000;

export const handleScrollToElement = (date?: Date) => {
  let currentHour: number | string = date ? date.getHours() : new Date().getHours();
  currentHour = `0${currentHour}`.slice(-2);
  const scrollToElement = document.querySelector(`[data-time="${currentHour}:00:00"]`);
  if (scrollToElement) {
    scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  return document.querySelector(`[data-time="${currentHour}:00:00"]`);
};
