import {
  ChevronRight,
  EmotionThemeProvider,
  styled,
  Tag,
  TextDS,
  TouchableView,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import { Check } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useHistory } from '@/core/routerLib';
import { getUserData } from '../../../utils/token';
import useQueryAutomaticMessages from '../../../hooks/account/useQueryAutomaticMessages';

const MainView = styled(TouchableView)(
  ({
    theme: {
      colorRoles,
      spacing,
      window: { isMobile },
    },
  }) => {
    return {
      height: isMobile ? 64 : 48,
      width: '100%',
      backgroundColor: colorRoles.surfaces.successSubtleDefault,
      borderRadius: 6,
      paddingTop: spacing('space050'),
      paddingRight: spacing('space100'),
      paddingBottom: spacing('space050'),
      paddingLeft: spacing('space200'),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: spacing('space200'),
      ':hover': {
        backgroundColor: colorRoles.surfaces.successSubtleHovered,
      },
      ':active': {
        backgroundColor: colorRoles.surfaces.successSubtlePressed,
      },
    };
  }
);

const BannerTextView = styled(View)({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
});

const GetStartedView = styled(View)({
  flexDirection: 'row',
  gap: 6,
  alignItems: 'center',
});

export default function AutomaticMessagesBanner() {
  const history = useHistory();

  const {
    window: { isMobile },
  } = useEmotionTheme();

  const { id: therapistUserID } = getUserData();

  const { automaticWelcomeMessage: isAutomaticWelcomeMessageActive } = useFlags();

  const {
    data: automaticMessages = [],
    isLoading,
    isError,
  } = useQueryAutomaticMessages(therapistUserID, isAutomaticWelcomeMessageActive);

  const handleBannerButtonPress = () => {
    history.push('/my-account?scrollTo=automaticMessages');
  };

  const isAutomationOn = automaticMessages.some(
    (it) => it.type === 'Personalized welcome' && it.enabled
  );

  if (!isAutomaticWelcomeMessageActive || isLoading) {
    return null;
  }

  return (
    <>
      {!isLoading && !isError && (
        <EmotionThemeProvider version="2.0.0">
          <MainView onPress={handleBannerButtonPress} dataQa="manageAutomaticMessagesBanner">
            <BannerTextView>
              {isAutomationOn ? (
                <>
                  <Check colorType="success" />
                  <TextDS variant="bodySm">
                    Your automated welcome message is sent to all new clients on match
                  </TextDS>
                </>
              ) : (
                <>
                  <Tag text="New!" color="successBoldDefault" />
                  <TextDS variant="bodySm">
                    Save time by setting up automated welcome messages
                  </TextDS>
                </>
              )}
            </BannerTextView>
            {isAutomationOn || isMobile ? (
              <ChevronRight colorType="brand" size="small" />
            ) : (
              <GetStartedView>
                <TextDS
                  colorRole="buttonBrandSecondary"
                  variant="headingMd"
                  style={{ fontWeight: 600 }}
                >
                  Get started
                </TextDS>

                <ChevronRight colorType="brand" size="small" />
              </GetStartedView>
            )}
          </MainView>
        </EmotionThemeProvider>
      )}
    </>
  );
}
