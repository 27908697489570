import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface CircleQuestionMarkProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const CircleQuestionMark: VoidFunctionComponent<CircleQuestionMarkProps> = ({
  width = 20,
  height = 21,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'circle question mark';
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title={titleText}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        d="M10 18.5C12.1217 18.5 14.1566 17.6571 15.6569 16.1569C17.1571 14.6566 18 12.6217 18 10.5C18 8.37827 17.1571 6.34344 15.6569 4.84315C14.1566 3.34285 12.1217 2.5 10 2.5C7.87827 2.5 5.84344 3.34285 4.34315 4.84315C2.84285 6.34344 2 8.37827 2 10.5C2 12.6217 2.84285 14.6566 4.34315 16.1569C5.84344 17.6571 7.87827 18.5 10 18.5ZM7.30625 7.66563C7.55312 6.96875 8.21563 6.5 8.95625 6.5H10.7781C11.8687 6.5 12.75 7.38438 12.75 8.47188C12.75 9.17813 12.3719 9.83125 11.7594 10.1844L10.75 10.7625C10.7437 11.1687 10.4094 11.5 10 11.5C9.58437 11.5 9.25 11.1656 9.25 10.75V10.3281C9.25 10.0594 9.39375 9.8125 9.62813 9.67812L11.0125 8.88438C11.1594 8.8 11.25 8.64375 11.25 8.475C11.25 8.2125 11.0375 8.00313 10.7781 8.00313H8.95625C8.85 8.00313 8.75625 8.06875 8.72188 8.16875L8.70937 8.20625C8.57188 8.59688 8.14062 8.8 7.75313 8.6625C7.36563 8.525 7.15938 8.09375 7.29688 7.70625L7.30937 7.66875L7.30625 7.66563ZM9 13.5C9 13.2348 9.10536 12.9804 9.29289 12.7929C9.48043 12.6054 9.73478 12.5 10 12.5C10.2652 12.5 10.5196 12.6054 10.7071 12.7929C10.8946 12.9804 11 13.2348 11 13.5C11 13.7652 10.8946 14.0196 10.7071 14.2071C10.5196 14.3946 10.2652 14.5 10 14.5C9.73478 14.5 9.48043 14.3946 9.29289 14.2071C9.10536 14.0196 9 13.7652 9 13.5Z"
        fill={color || colors.accessibilityGreenDark}
      />
    </Svg>
  );
};

export default CircleQuestionMark;
