import { styled, TextDS, View } from '@talkspace/react-toolkit';
import { CircleExclamation, CircleInfo } from '@talkspace/react-toolkit/src/designSystems/icons';

const UpperBannerView = styled(View)<{ isError: boolean }>(
  ({ isError, theme: { colorRoles, spacing } }) => {
    return {
      backgroundColor: isError
        ? colorRoles.surfaces.criticalSubtleDefault
        : colorRoles.surfaces.infoSubtle,
      padding: spacing('space200'),
    };
  }
);

const BannerText = styled(TextDS)(({ theme: { colorRoles } }) => {
  return {
    color: `${colorRoles.typography.textSubtle} !important`,
  };
});

const getInvalidTokensString = (invalidTokens: string[]) =>
  invalidTokens.length > 1
    ? `The codes ${invalidTokens.join(', ')} are broken`
    : `The code ${invalidTokens[0]} is broken`;

interface Props {
  isError: boolean;
  invalidTokens: string[];
  dataTags: string[];
}

export default function UpperFormBanner({ isError, invalidTokens, dataTags }: Props) {
  const dataTagsString = dataTags.join(', ');

  return (
    <UpperBannerView isError={isError}>
      <View style={{ flexDirection: 'row', gap: 8 }}>
        {isError && invalidTokens.length > 0 ? (
          <>
            <View>
              <CircleExclamation colorType="critical" />
            </View>
            <BannerText variant="bodyXs">
              {`${getInvalidTokensString(
                invalidTokens
              )}. Please ensure the code has brackets on both sides with no spaces or typos. Available codes are ${dataTagsString}. If you’re still getting an error, reset to default and try again.`}
            </BannerText>
          </>
        ) : (
          <>
            <View>
              <CircleInfo colorType="brand" />
            </View>
            <BannerText variant="bodyXs">
              This message has codes with brackets which get replaced with real client info, making
              it easier to personalize.
            </BannerText>
          </>
        )}
      </View>
    </UpperBannerView>
  );
}
