import { Node, Path, Text } from 'slate';
import { useCallback, useState } from 'react';

import { DataTagStatusError, RangeWithDataTags } from './types';

const dataTagRegex = /(\{[a-zA-Z_]+})|(\{[a-zA-Z_]+[^}\s]*)|([a-zA-Z_]+})/g;

export default function useHighlightDataTags(dataTags: string[]) {
  const [editorError, setEditorError] = useState<DataTagStatusError | null>(null);
  const [invalidTokens, setInvalidTokens] = useState<string[]>([]);

  const clearEditorError = () => {
    setEditorError(null);
    setInvalidTokens([]);
  };

  const highlightDataTags = useCallback(
    ([node, path]: [Node, Path]) => {
      if (!Text.isText(node)) {
        return [];
      }

      const { text } = node;

      const ranges: RangeWithDataTags[] = [];

      let matches = dataTagRegex.exec(text);

      while (matches !== null) {
        const [match, matchValidTag, matchMissingOpeningBrace, matchMissingClosingBrace] = matches;
        const start = matches.index;
        const matchLength = match.trim().length;
        const end = start + matchLength;

        if (matchValidTag) {
          // Lexically valid tag
          const matchWithoutBraces = matchValidTag.replaceAll('{', '').replaceAll('}', '');
          if (dataTags.includes(matchWithoutBraces)) {
            // Known valid tag
            ranges.push({
              anchor: { path, offset: start },
              focus: { path, offset: end },
              dataTagStatus: 'valid',
              dataTag: matchWithoutBraces,
            });
          } else {
            // Unknown tag
            setEditorError('unknown_tag');
            setInvalidTokens((prev) =>
              prev.includes(matchValidTag) ? prev : [...prev, matchValidTag]
            );
            ranges.push({
              anchor: { path, offset: start },
              focus: { path, offset: end },
              dataTagStatus: 'unknown_tag',
            });
          }
        } else if (matchMissingOpeningBrace) {
          // Incomplete tag, missing opening brace
          setEditorError('invalid_format');

          setInvalidTokens((prev) =>
            prev.includes(matchMissingOpeningBrace) ? prev : [...prev, matchMissingOpeningBrace]
          );

          ranges.push({
            anchor: { path, offset: start },
            focus: { path, offset: start + matchLength },
            dataTagStatus: 'invalid_format',
          });
        } else if (matchMissingClosingBrace) {
          // Incomplete tag, missing closing brace
          setEditorError('invalid_format');

          setInvalidTokens((prev) =>
            prev.includes(matchMissingClosingBrace) ? prev : [...prev, matchMissingClosingBrace]
          );

          ranges.push({
            anchor: { path, offset: start },
            focus: { path, offset: start + matchLength },
            dataTagStatus: 'invalid_format',
          });
        }

        matches = dataTagRegex.exec(text);
      }

      return ranges;
    },
    [dataTags]
  );

  return {
    highlightDataTags,
    editorError,
    invalidTokens,
    clearEditorError,
  };
}
