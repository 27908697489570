import { useQuery } from 'react-query';

import { EmergencyContactData } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { emergencyContactQueryKey } from './queryKeys';

const fetchEmergencyContact = (userID?: number) => async () => {
  const {
    data: { data },
  } = await apiWrapper.get(`${apiHelper().apiEndpoint}/api/v1/clients/${userID}/emergency-contact`);
  return data;
};

export default function useQueryEmergencyContact(userID?: number) {
  return useQuery<EmergencyContactData, Error>({
    queryKey: emergencyContactQueryKey(userID),
    queryFn: fetchEmergencyContact(userID),
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!userID,
  });
}
