import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '@/core/api/apiWrapper';

import { clientConditionsQueryKey } from './queryKeys';
import { ClientCondition } from '../types/clinical';

const fetchClientConditions = (clientUserID?: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}/conditions`
  );
  return data.data;
};

export default function useQueryClientConditions(clientUserID?: number) {
  return useQuery<Array<ClientCondition>, Error>({
    queryKey: clientConditionsQueryKey(clientUserID),
    queryFn: fetchClientConditions(clientUserID),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clientUserID,
  });
}
