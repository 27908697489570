import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { customerInformationQueryKey } from './queryKeys';

export interface CustomerInformation {
  clientUserID: number;
  preferredName: string | null;
  state: string;
  country: string;
  gender: string;
  dateOfBirth: string;
  yearOfBirth: number;
  relationshipStatus: string | null;
  educationLevel: string | null;
  workStatus: string | null;
  ethnicity: string | null;
  therapistEthnicityPreference: string | null;
  therapistGenderPreference: string | null;
}

const fetchCustomerInformation = (userID?: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/clients/${userID}/customer-information`
  );
  return data.data;
};

export default function useQueryCustomerInformation(userID?: number) {
  return useQuery<CustomerInformation, Error>({
    queryKey: customerInformationQueryKey(userID),
    queryFn: fetchCustomerInformation(userID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!userID,
  });
}
