import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import { calendarSyncStatus } from './queryKeys';

export type OAuthProviderName = 'google';

interface CalendarSyncStatusResponse {
  isConnected: boolean;
  syncedEmail?: string;
  shouldReconnect: boolean;
}

interface CalendarSyncStatusParams {
  therapistUserID: string;
  provider: OAuthProviderName;
}

const fetchCalendarSyncStatus = (params: CalendarSyncStatusParams) => async () => {
  const { therapistUserID, provider } = params;
  const { data } = await apiWrapper.get(
    `${
      apiHelper().apiEndpoint
    }/v2/therapists/${therapistUserID}/provider/${provider}/calendar-sync/status`
  );

  return data.data;
};

const useQueryCalendarSyncStatus = (params: CalendarSyncStatusParams) =>
  useQuery<CalendarSyncStatusResponse, Error>({
    queryKey: calendarSyncStatus(params.provider),
    queryFn: fetchCalendarSyncStatus(params),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: 5 * 1000 * 60,
  });

export default useQueryCalendarSyncStatus;
