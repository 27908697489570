import { useState } from 'react';
import {
  styled,
  TextDS,
  View,
  Collapsible,
  TouchableView,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { ChevronDown, ChevronUp } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useParams } from '@/core/routerLib';
import useQuerySmartInsights from '../../../../hooks/useQuerySmartInsights';
import SmartInsightsUnavailable from './SmartInsightsUnavailable';
import SmartInsightsAvailable from './SmartInsightsAvailable';
import SmartInsightsLoading from './SmartInsightsLoading';
import SmartInsightsError from './SmartInsightsError';
import SmartInsightsReady from './SmartInsightsReady';
import useMutationCreateSmartInsight from '../../../../hooks/useMutationCreateSmartInsight';
import AnnouncementTooltip from './AnnouncementTooltip';
import NotReadyView from './NotReadyView';

const MainView = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: spacing('space200'),
    paddingBottom: spacing('space100'),
    gap: spacing('space150'),
  };
});

const TitleView = styled(TouchableView)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export default function SmartInsights() {
  const { roomID } = useParams<{ roomID: string }>();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const { spacing } = useEmotionTheme();

  const { data: smartInsights } = useQuerySmartInsights(Number(roomID));

  const { mutate: createSmartInsight, status: createInsightStatus } =
    useMutationCreateSmartInsight();

  const handleGeneratePress = () => {
    createSmartInsight({ roomID: Number(roomID) });
  };

  if (!smartInsights) {
    return null;
  }

  return (
    <MainView>
      <TitleView onPress={() => setIsCollapsed((prev) => !prev)}>
        <TextDS variant="headingMd">Smart Insights</TextDS>

        {isCollapsed ? <ChevronDown /> : <ChevronUp />}
      </TitleView>

      <Collapsible id="smartInsightsSection" isCollapsed={isCollapsed}>
        <View style={{ marginBottom: spacing('space100') }}>
          <View style={{ marginBottom: spacing('space150') }}>
            <TextDS variant="bodySm" colorRole="textSubtle">
              <AnnouncementTooltip inline /> help you prepare for upcoming sessions by providing an
              overview of your client's most recent session and clinical information.
            </TextDS>
          </View>

          {(() => {
            if (createInsightStatus === 'loading') {
              return (
                <NotReadyView>
                  <SmartInsightsLoading />
                </NotReadyView>
              );
            }

            if (createInsightStatus === 'error') {
              return (
                <NotReadyView style={{ height: 196 }}>
                  <SmartInsightsError onTryAgainPress={handleGeneratePress} />
                </NotReadyView>
              );
            }

            if (smartInsights.insight) {
              return (
                <SmartInsightsReady
                  insight={smartInsights.insight.result}
                  canGenerate={smartInsights.canGenerate}
                />
              );
            }

            if (smartInsights.canGenerate) {
              return (
                <NotReadyView style={{ height: 196 }}>
                  <SmartInsightsAvailable onGeneratePress={handleGeneratePress} />
                </NotReadyView>
              );
            }

            return (
              <NotReadyView>
                <SmartInsightsUnavailable />
              </NotReadyView>
            );
          })()}
        </View>
      </Collapsible>
    </MainView>
  );
}
